import React from 'react'
// import {v4 as uuid} from 'uuid'

import Ui from './index.ui'

function ModulosPeopleContainer() {

    // const dataCompare = {
    //     items:[

    //         {
    //             id: uuid(),
    //             title: "2 Play",
    //             price: 880.21,

    //             gruopPlan: 'Internet + Televisión + Telefonía',
    //             speedDownload: '16 Mbps',
    //             speedUpdate: '2.0 Mbps',
    //             channelsHD: '15 canales HD',
    //             channelSD: '12 en SD',
    //             calls: 'Ilimitado',

    //             exelentFor: 'Exelente para navegar',
    //             exelentForDesciption: 'Para los que navegan todo el día',
    //         },
    //         {
    //             id: uuid(),
    //             title: "3 Play",
    //             price: 980.22,
    //             gruopPlan: 'Internet + Televisión + Telefonía',
    //             speedDownload: '16 Mbps',
    //             speedUpdate: '2.0 Mbps',
    //             channelsHD: '15 canales HD',
    //             channelSD: '12 en SD',
    //             calls: 'Ilimitado',
                
    //             exelentFor: 'Excelente para Videojuegos',
    //             exelentForDesciption: 'Para los que navegan todo el día',
    //         },
    //         {
    //             id: uuid(),
    //             title: "4 Play",
    //             price: 700.21,
                
    //             gruopPlan: 'Internet + Televisión + Telefonía',
    //             speedDownload: '16 Mbps',
    //             speedUpdate: '2.0 Mbps',
    //             channelsHD: '15 canales HD',
    //             channelSD: '12 en SD',
    //             calls: 'Ilimitado',
                
    //             exelentFor: 'Excelente para Descargas',
    //             exelentForDesciption: 'Para los que navegan todo el día',
    //         },
    //         {
    //             id: uuid(),
    //             title: "1 Play",
    //             price: 100.24,
                
    //             gruopPlan: 'Internet + Televisión + Telefonía',
    //             speedDownload: '16 Mbps',
    //             speedUpdate: '2.0 Mbps',
    //             channelsHD: '15 canales HD',
    //             channelSD: '12 en SD',
    //             calls: 'Ilimitado',
                
    //             exelentFor: 'Excelente para transmitir',
    //             exelentForDesciption: 'Para los que navegan todo el día',
    //         },
            
    //     ],
    //     isFetching: false,
    // }
    return (
        <Ui 
            // dataCompare={dataCompare}
        />
        )
    }

export default ModulosPeopleContainer
