import React, { useEffect, useState } from 'react'
import Carousel from 'react-grid-carousel'
import '../../../../Sass/includes/Components/modules/channelGuide.sass'
import { v4 as uuid } from 'uuid'

function MChannelsPartModal(props) {
	const {
        dataChannels,
        planName,
		dataCategorias,
    } = props
	const [channelsFiltered, setChannelsFiltered] = useState(dataChannels)
	const [idCategory, setIdCategory] = useState("")
    const [optionsCategoriesFilter, setOptionsCategoriesFilter] = useState([])

    const getOptionsInit = async()=>{
        let listCategories = []
        dataChannels.forEach((c, index) => {
            if(!listCategories.includes(c.fi_categoria)){
                listCategories.push(c.fi_categoria)
            }
            return true;
        });
        let listOptions = dataCategorias.filter(cat => listCategories.includes(cat.fi_categoria))
        const optionsCategories = listOptions?.length ? listOptions.map((item, index) => (
            <option key={uuid()} value={item.fi_categoria + "_" + item.fc_descripcion}>{item.fc_descripcion}</option>
        )) : null
        setOptionsCategoriesFilter(optionsCategories)
    }

	const getChannelsByCategoryId = async (id) => {
		let idCat = parseInt(id.split("_")[0])
		setIdCategory(id)
		let listChannels = dataChannels
		if(idCat){
			const listChannels2 = dataChannels
			
			listChannels = listChannels2.filter(c => c.fi_categoria === idCat);
		}
		setChannelsFiltered(listChannels)
		// console.log("getChannelsByCategoryId", idCat, listChannels)
		
	}
    
    useEffect(() => {
		getOptionsInit()
		// eslint-disable-next-line
	}, [])
    
    // console.log("MChannelsPartModal dataChannels", dataChannels)
	// const channelsModal = ["disney-channel", "amc-hd", "axn", "cine-canal", "cine-latino", "cinemax", "claro-cinema", "dhe", "film-arts", "fxm", "golden", "hbo", "paramount", "space-hd", "tcm", "universal", "amc-hd", "axn", "cine-canal", "cine-latino", "cinemax", "claro-cinema", "dhe", "film-arts", "fxm", "golden"];
	return (
		<div className="modalClaro">
			<div className="modalChannels">
				<h4>Canales que incluye el Plan {planName}</h4>
				{
					optionsCategoriesFilter?.length > 0 &&
					<div className="modalChannelsFilter">
						<label>Filtrar por:</label>
						<span className="select">
						<select value={idCategory} onChange={(selected) => { getChannelsByCategoryId(selected.target.value) }}>
								<option value="">Todos</option>
								{optionsCategoriesFilter}
							</select>
						</span>
					</div>
				}
				<div className="modalChannelsCarousel">
					<Carousel
						cols={4}
						rows={5}
						gap={20}
						showDots={true}
						dotColorActive={"#3C3C3C"}
						dotColorInactive={"#BBBBBB"}
						responsiveLayout={[
							{
								breakpoint: 1280,
								cols: 4
							},
							{
								breakpoint: 960,
								cols: 3
							},
							{
								breakpoint: 760,
								cols: 2
							},
							{
								breakpoint: 480,
								cols: 1 
							},
						]}
						mobileBreakpoint={320}
					>
                        {channelsFiltered.length ? channelsFiltered.map(channel => (
                            <Carousel.Item key={uuid()}>
                                <div className="chGuideChannelBox" data-idcanal={channel.fi_canal}>
                                    <div className="chGuideChannelBoxImg">
                                        {
                                            channel.fc_imagen &&
                                            <picture>
                                                <source data-srcset={`${channel.fc_imagen.split(".")[0]}.webp`} type="image/webp" />
                                                <source data-srcset={`${channel.fc_imagen}`} type={`image/` + channel.fc_imagen.split(".")[1]} />
                                                <img className="lazyload" data-src={`${channel.fc_imagen}`} alt="" />
                                            </picture>
                                        }
                                    </div>
                                    <div className="chGuideChannelBoxText">
                                        <p>{channel.fc_nombre}</p>
                                        <span>{channel.fc_descripcion} {channel.fi_no_canal ? "#" + channel.fi_no_canal : ""}</span>
                                        {
                                            channel.fc_texto_detalle &&
                                            <a href={channel.fc_url_detalle ? channel.fc_url_detalle : "#!"} target={channel.fc_target_detalle ? channel.fc_target_detalle : "_self"} className="textLink">{channel.fc_texto_detalle}</a>
                                        }
                                    </div>
                                </div>
                            </Carousel.Item>
                        )) : null}
					</Carousel>
				</div>
			</div>
		</div>
	)
}
MChannelsPartModal.defaultProps = {}
MChannelsPartModal.propTypes = {}

export default MChannelsPartModal
