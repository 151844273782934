import React, {useState, useEffect,}from 'react'

// Components

import C08V3PeopleOrg from './../../../Components/Organisms/Personas/C08/v3'
import C08V4PeopleOrg from './../../../Components/Organisms/Personas/C08/v4'
import C08V5PeopleOrg from './../../../Components/Organisms/Personas/C08/v5'
import {URL_WEB_SERVICE_LOCAL_STORAGE} from '../../../constants/general'
import { ChannelsService, CategoriesService } from '../../../actions/HttpCalls'
import { HttpClient } from '../../../actions/HttpClient'

const httpClient = new HttpClient();
const categoriesService = new CategoriesService(httpClient);
const channelsService = new ChannelsService(httpClient);

function HomePeopleContainerUi(props) {
    const [dataChannelsBk, setDataChannelsBk] = useState([])
    const [dataCategorias, setDataCategorias] = useState([])

    const getValuesDefault = async () => {
        if( URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 ){
            const categories = await categoriesService.getCategoriesItems()
            setDataCategorias(categories)
            
            const channels = await channelsService.getChannelsItems()
            setDataChannelsBk(channels)
            
            console.log("categorias", categories)
            console.log("channels", channels)
        }
	}
    useEffect(() => {
        if(localStorage.getItem('url_detalle_default') && localStorage.getItem('id_vigencia') === "CHN"){
            getValuesDefault()
        }else{
            setDataCategorias([])
            setDataChannelsBk([])
        }
		// eslint-disable-next-line
	}, [])

    return (
        <>
        {
            URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 || URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("localhost") > -1 ?
                localStorage.getItem('version_comparador') === 'v4' ?
                    <>
                        <C08V4PeopleOrg 
                            dataChannelsBk={dataChannelsBk}
                            dataCategorias={dataCategorias} 
                        /> 
                    </> 
                    :
                    <C08V3PeopleOrg 
                        dataChannelsBk={dataChannelsBk}
                        dataCategorias={dataCategorias} 
                    />
                :
                localStorage.getItem('version_comparador') === 'v4' ?
                    <>
                        <C08V4PeopleOrg 
                            dataChannelsBk={dataChannelsBk}
                            dataCategorias={dataCategorias} 
                        /> 
                    </> 
                    :
                    localStorage.getItem('version_comparador') === 'v5' ?
                    <>
                        <C08V5PeopleOrg 
                            dataChannelsBk={dataChannelsBk}
                            dataCategorias={dataCategorias} 
                        /> 
                    </> 
                    :

                    <C08V3PeopleOrg 
                        dataChannelsBk={dataChannelsBk}
                        dataCategorias={dataCategorias} 
                    />
        }
        </>
    )
}


export default HomePeopleContainerUi
